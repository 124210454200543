@import './variables';
@import './font';
@import './mixins';
@import './tittle';
@import './utility';
@import './button';

input,
select {
    border-radius: 5px !important;
    border: 1px solid #D8D6DE !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    height: 43px !important;
    padding-left: 16px !important;
    color: #5E5873 !important;

}

textarea {
    border-radius: 5px !important;
    border: 1px solid #D8D6DE !important;
}

.select__input-container {
    input {
        height: 30px !important;
        border: none !important;
    }
}

.required-star {
    color: red !important;
}

.rz-text-primary {
    color: #6E6B7B !important;
}

.rz-text-secondary {
    color: #5E5873 !important;
}

.rz-text-14 {
    font-size: 14px !important;
}

.rz-text-16 {
    font-size: 16px !important;
}

.rz-thead-style {
    background-color: #D8D6DE !important;
}

.rz-thead-style th {
    color: #6E6B7B !important
}

.ryzer-text-blue {
    color: #7367F0 !important;
}

.expense-field {
    border-radius: 5px;
    border: 1px solid #D8D6DE;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 45px !important;
    color: #5E5873 !important;
    height: 43px !important;

    &::placeholder {
        font-family: 'Montserrat', sans-serif !important;
        color: #B9B9C3 !important;
    }
}
.dollor-icon{
    color: #28C76F !important;
    background: #28C76F1F;
    width: 40px;
    height: 40px;
    font-size: 17px !important;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.user-icon{
    color:  #00CFE8 !important;
    background: #00CFE81F;
    width: 40px;
    height: 40px;
    font-size: 19px !important;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.active-tab{
    background-color: #7367F0 !important;
    box-shadow: none !important;
    color: white !important;
}
.cash-flow-col{
    padding-left: 5px !important;
}
.custom-file-input ~ .custom-file-label::after{
    background: #7367F0 !important;
    font-family: 'Montserrat' !important;
    font-weight: 500 !important;
    color: white !important;
}
@media (min-width: 10px) {
    .legal-main {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}